<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">{{ organisation.title || 'New Organisation' }}</h1>
    <v-tabs v-model="tabs" background-color="transparent">
      <v-tab exact-path :to="'/organisations/' + organisation.id">Details</v-tab>
      <v-tab
        exact-path
        :to="'/organisations/' + organisation.id + '/family-members'"
        v-if="organisation.id > 0 && isHost"
        >Family Members</v-tab
      >
      <v-tab exact-path :to="'/organisations/' + organisation.id + '/contacts'" v-if="organisation.id > 0 && !isHost"
        >Contacts</v-tab
      >
    </v-tabs>
    <v-card>
      <v-card-text>
        <v-tabs-items v-model="tabs" transition="false">
          <v-card flat>
            <v-card-text>
              <router-view />
            </v-card-text>
          </v-card>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';

export default {
  name: 'OrganisationView',
  beforeRouteEnter(to, from, next) {
    store.dispatch('organisation/get', to.params.id).then(() => {
      next();
    });
  },
  computed: {
    ...mapGetters('organisation', ['loading', 'error', 'organisation', 'meta']),
    isHost: function () {
      return this.organisation.organisation_types_id == 4 && this.organisation.id > 0;
    }
  },
  data: () => ({
    tabs: null,
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'Organisations',
        exact: true,
        to: '/organisations'
      },
      {
        text: 'Organisation',
        disabled: true,
        exact: true,
        to: '/organisations/:id'
      }
    ]
  })
};
</script>
